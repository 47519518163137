@import "../../../../../../lib/uio1/profile/css/util.less";

#hidnav a[href="#bottomnav"] {
  display: none;
}

.not-for-ansatte {
  .header-search-collapsable .header-search-expand {
    background-color: @color-contrast !important;
  }
  #head-wrapper {
    background-color: @color-primary;
    #head {
      height: 200px;
      .head-menu {
        right: 110px;
        .language {
          display: none;
        }
        .vrtx-dropdown-link {
          background: transparent
            url("../images/language-dropdown-toggle-black.png") no-repeat scroll
            100% 53%;
          margin-right: 7px;
        }
      }
      #header {
        height: 200px;
        .head-logo {
          color: @color-dark;
          left: 0px;
          position: absolute;
          top: 15px;
          img {
            width: 450px;
          }
          span.header-title {
            .font-size(20);
            position: absolute;
            width: 470px;
            top: 130px;
            left: 140px;
          }
        }
        a.head-logo:hover,
        a.head-logo:focus {
          text-decoration: underline;
        }
      }
      .head-menu > ul a,
      .vrtx-login-manage-component > a {
        color: @color-dark;
      }
    }
    .uio-app-name {
      display: none;
    }

    .header-search {
      top: 18px;
      z-index: 10;

      input[type="text"] {
        background: @color-neutral--light;
        .placeholderColor(@color-dark, @color-dark) !important;
        color: @color-neutral--dark;
      }
      .header-search-expand,
      button {
        background-color: @color-contrast;
        color: @color-light;
        .transition(all, 0.5s, ease-in);
        &:focus,
        &:hover {
          background-color: @color-tertiary;
          color: @color-light;
        }
      }
    }
  }
}

#main
  .button:not(.comment-delete-button):not(#vrtx-comments-delete-all):not(.red):not(.white):not(.program-create-print):not(.program-create-send-dialog):not(.vrtx-program-read-more):not(.vrtx-program-how-search),
#main input[type="button"]:not(.red),
#main input[type="submit"]:not(.submit-comment-button):not(.red),
#main input[type="cancel"]:not(.red),
#main
  button:not(.comment-delete-button):not(#vrtx-commments-delete-all):not(.red):not(.white) {
  background-color: @color-primary;
  color: @color-dark;
  &:focus,
  &:hover {
    background-color: @color-neutral--light;
  }
}

#globalnav {
  background: @color-contrast none repeat scroll 0 0;
  ul {
    li,
    li.vrtx-active-item {
      background: @color-contrast;
      a {
        padding: 12px 15px 13px;
        color: @color-light;
        //.transition(all, 0.5s, ease-in);
      }
      a:hover,
      a:focus {
        text-decoration: underline;
        color: @color-light !important;
        background-color: @color-secondary !important;
      }
    }
    li.vrtx-active-item a {
      background-color: @color-light !important;
      color: @color-dark !important;
    }
  }
}

// leftmenu
#left-main ul.vrtx-breadcrumb-menu li a {
  &.vrtx-marked {
    background-color: @color-contrast !important;
    color: @color-light;
    border: none;
  }
}

.vrtx-frontpage-box-picture {
  z-index: -1;
}

.vrtx-frontpage-box.vrtx-header-false.vrtx-more-false.content-over-image-left
  .vrtx-box-content {
  margin-top: 40px !important;
}

#left-main .vrtx-breadcrumb-menu li.vrtx-child > span.vrtx-after-marked,
#left-main .vrtx-breadcrumb-menu li.vrtx-child > a.vrtx-after-marked,
#left-main .vrtx-breadcrumb-menu li.vrtx-child > span.vrtx-marked,
#left-main .vrtx-breadcrumb-menu li.vrtx-child > a.vrtx-marked {
  border-top: none;
}

table.vrtx-person-listing .vrtx-person-listing-phone {
  display: none;
}

a.vrtx-more::before,
div.vrtx-more a::before,
a.all-messages::before,
a.all-comments::before,
.footer > .vrtx-search-scope a::before,
a.more-url::before {
  background: url("../images/arrow-forward-blue.svg") no-repeat 0 0;
}
.ui-accordion.ui-widget {
  .ui-accordion-header .ui-icon {
    background: transparent url("../images/icon-chevron-right-blue.svg")
      no-repeat center left;
  }
  .ui-accordion-header.ui-accordion-header-active .ui-icon {
    background-image: url("../images/icon-chevron-down-blue.svg");
  }
}

table th {
  font-weight: bold;
}

#vrtx-frontpage .button-flex {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-flow: wrap;
  width: 100%;
  p {
    margin: 10px 0;
    width: 300px;
    a.button {
      height: 100%;
      padding: 20px 0 0 15px;
    }
  }
}

#bottomnav {
  display: none !important;
}

#uio-app-menu-wrapper,
#footer-wrapper {
  background: @color-neutral--light;
}

//footer
#footer-wrapper {
  color: @color-neutral--dark;
  #footers {
    background: none;
    a {
      color: @color-link;
    }
   
    .vrtx-dropdown-component-toggled .vrtx-login-manage-link {
      background: rgba(0, 0, 0, 0) url("../dist/src/images/dropdown-black.png")
        no-repeat scroll right center / 22px auto;
    }
    h2 {
      color: @color-neutral--dark;
    }
    .eu-flag > span {
      padding-top: 20px;
      float: left;
      padding-left: 10px;
    }
    .eu-flag > img {
      padding-top: 20px;
      float: left;
    }
    .uio-logo {
      margin-left: 160px;
      margin-top: 28px;
      position: absolute;
    }
    .social-components {
      padding: 5px 0 12px;
      margin-left: 0px;
      margin-top: 0px;
      a {
        &.facebook,
        &.twitter-x {
          color: transparent;
          margin-right: 5px;
          padding: 10px 10px 20px 24px;
          &:hover,
          &:focus {
            color: transparent;
            box-shadow: none;
            background-color: transparent;
            opacity: 0.6;
          }
        }
        &.facebook {
          background: transparent url("../uio1/images/social-list/black-svg/facebook.svg") no-repeat scroll left center;
          background-size: 40px;
        }
        &.twitter-x {
          background: transparent url("../uio1/images/social-list/black-svg/twitter-x.svg") no-repeat scroll left center;
          background-size: 40px;
        } 
      }
    }
  }
}

@media only screen and (max-width: 16cm) and (orientation: portrait),
  only screen and (max-width: 19cm) and (orientation: landscape) {
  .not-for-ansatte {
    #head-wrapper #head {
      &,
      #header {
        height: 200px;
      }
      #header  {
        padding: 25px 15px 15px 15px;
        a.head-logo {
          top: 15px;
          position: static;
          span.header-title {
            .font-size(16);
            top: 150px;
            left: 14px;
            max-width: 100%;
            line-height: normal;
          }
        }
      }
    }

    #menu-wrapper .menu li.active {
      background: @color-contrast none repeat scroll 0 0;
    }
    #lang-link a {
      display: none !important;
    }
  }
  #vrtx-frontpage .button-flex {
    flex-direction: row;
    flex-flow: wrap;
    p {
      width: 100%;
    }
  }
}

.transition (@prop: all, @time: 1s, @ease: linear) {
  -webkit-transition: @prop @time @ease;
  -moz-transition: @prop @time @ease;
  -o-transition: @prop @time @ease;
  -ms-transition: @prop @time @ease;
  transition: @prop @time @ease;
}

/*------------------------------------*\
# COLOR
\*------------------------------------*/

// Primary, secondary etc...

@color-primary : #f8ee32;
@color-secondary : #2e2e2e;
@color-tertiary : #3b3b3b;
@color-contrast : #1e1e1e;
@color-link : #4C81CA;

// Neutral colors
@color-neutral--dark : #4f4f4f;
@color-neutral--light : #F4F4F4;

// Suplementary colors
@color-light : #ffffff;
@color-dark : #2b2b2b;